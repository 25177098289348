import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useReducer
} from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from '@reach/router'
import {
  __,
  always,
  isNil,
  unless,
  isEmpty,
  values,
  filter,
  compose,
  gt,
  sum,
  map,
  converge,
  multiply,
  identity,
  mergeWith,
  subtract,
  pick,
  prop,
  propOr,
  assoc,
  assocPath,
  o,
  sortBy,
  applySpec,
  find,
  both,
  contains,
  nth,
  unnest,
  pathOr,
  join,
  concat,
  groupBy,
  path,
  mapObjIndexed,
  toPairs,
  apply,
  evolve,
  nthArg,
  all,
  memoizeWith,
  take,
  prepend,
  uniqBy,
  sort,
  flatten,
  reverse,
  over,
  lensProp,
  defaultTo
} from 'ramda'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import MarkerClusterer from '@google/markerclusterer'
import Checkbox from 'components/Checkbox'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import GpsFixed from '@material-ui/icons/GpsFixed'
import GpsNotFixed from '@material-ui/icons/GpsNotFixed'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Button from 'components/Button'
import NavContext from 'components/NavProvider'
import ProductContext from 'components/ProductContext'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useProduct from 'hooks/useProduct'
import { mediaQuery } from 'utils/style'
import Image from 'components/Image'
import m1 from 'images/m1.png'
import m2 from 'images/m2.png'
import m3 from 'images/m3.png'
import Close from '@material-ui/icons/Close'
import Background from 'components/Container'
import countryBoundaries from 'utils/country-boundaries'
import useCountryCode from 'components/CountryProvider'

const sortResults = compose(
  flatten,
  reverse,
  values,
  map(sortBy(prop('distance'))),
  groupBy(prop('internalDealerRanking')),
  map(over(lensProp('internalDealerRanking'), defaultTo(1)))
)

const gtmPush = memoizeWith(
  (d, e) => `${d?.id}${e}`,
  (dealer, event) => () => {
    dataLayer.push({
      event,
      postCode: dealer.address.postCode,
      dealer: dealer.id
    })
  }
)

const parseGeocodeResult = o(
  applySpec({
    formData: {
      street: o(prop('long_name'), find(o(contains('route'), prop('types')))),
      city: o(
        prop('long_name'),
        find(
          o(both(contains('locality'), contains('political')), prop('types'))
        )
      ),
      postCode: o(
        prop('long_name'),
        find(o(contains('postal_code'), prop('types')))
      ),
      nr: o(
        prop('long_name'),
        find(o(contains('street_number'), prop('types')))
      )
    }
  }),
  pathOr({}, [0, 'address_components'])
)

const meterPerDeg = (6378137 * 2 * Math.PI) / 360

const ResultActions = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  flex-wrap: wrap;
`

const ResultAction = styled.div`
  cursor: pointer;
  height: ${({ height }) => height || '168px'};
  width: ${({ width }) => width || '168px'};
  margin: 16px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  > div {
    filter: brightness(0) invert(77%) sepia(45%) saturate(3547%)
      hue-rotate(338deg) brightness(101%) contrast(91%);
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.base.orange};
    background: ${({ theme }) => theme.colors.base.orange};
    color: white;
    > div {
      filter: brightness(0) invert(1);
    }
    > span {
      color: white;
    }
  }
`
// * {
//   filter: brightness(0) invert(1);
// }

const FilterContainer = styled.div`
  width: 365px;
  background-color: white;
  height: 100%;
  position: absolute;
  left: ${({ out }) => (out ? 0 : '-365px')};
  top: 0;
  transition: left 0.3s ease-in-out;
  z-index: 1;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 52px;
  color: black;
`

const FilterButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: calc(50% - 30px);
  border: 1px solid ${({ theme }) => theme.colors.base.gray};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  &::after {
    content: ' ';
    height: 16px;
    width: 16px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.base.gray};
    transform: translateX(5px) rotate(${({ out }) => (out ? '-45' : '135')}deg);
  }
`

const Content = styled.div`
  p,
  span {
    white-space: pre;
  }
  flex-wrap: wrap;
  padding: 32px;
  min-height: 200px;
  height: 100%;
  display: flex;
  background-color: white;
  color: black;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
  ${mediaQuery()}
`

const MapContainer = styled.div`
  overflow: hidden;
  height: 72vh;
  position: relative;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
`

const Container = styled(Grid)``

const InputGroup = styled.div`
  flex-basis: auto;
  flex-shrink: 0;
  > * {
    width: 100%;
  }
`

const StyledInputLabel = styled.h6`
  font-size: 16px;
  color: black;
`

const StyledTextField = styled(TextField)`
  && {
    background-color: ${({ theme }) => theme.colors.base.lightGray};
    margin-bottom: 16px;
  }
`

const Item = styled(Grid)`
  && {
    && {
      margin-top: 64px;
    }
  }
`
const More = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const LocationButton = styled.button`
  background-color: white;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-right: 14px;
  padding: 4px;
`

const GroupLabel = styled.h5`
  font-size: 18px;
  color: black;
  font-weight: 700;
`
const FilterLabel = styled.h4`
  font-size: 18px;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
`

const ResultItem = styled.div`
  margin: 16px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const CalloutWrapper = styled.div`
  background: rgba(255, 255, 255, 0.86);
  width: 300px;
  width: fit-content;
  box-shadow: 0 0 3px 1px #000a;
  padding: 1rem;
  transform: translate(-50%, -122%);
  z-index: 10000000;
  display: flex;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid rgba(255, 255, 255, 0.86);
    left: calc(50% - 12px);
    bottom: -12px;
  }
`

const CloseButton = styled(Close)`
  font-size: 2rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1500;
  cursor: pointer;
`

const Column = styled.div`
  flex: 1 1 auto;
  p {
    word-break: keep-all;
    width: max-content;
  }
`

const NoDealerItem = styled.div`
  width: fit-content;
  h2 {
    transition: color 0.3s ease-in-out;
  }
  :hover {
    h2 {
      color: black;
    }
    :after {
      margin-left: 10px;
    }
  }
  :after {
    content: ' ';
    position: absolute;
    height: 1rem;
    width: 1rem;
    border-color: ${({ theme }) => theme.colors.base.orange};
    border-style: solid;
    border-top-width: 2px;
    border-left: 0;
    border-bottom: 0;
    border-right-width: 2px;
    left: 100%;
    bottom: 0;
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
`

// Google Maps info balloon - Dealer information
const Callout = ({
  callout, data, openPhoneDialog, openModal, setCallout
}) => {
  const {
    phoneLabel, callIcon, requestQuoteLabel, requestIcon
  } = data || {}

  if (!callout) return null
  console.log(callout)
  return (
    <CalloutWrapper>
      <CloseButton onClick={() => setCallout(null)} />
      <Column>
        <h4>{callout?.name}</h4>
        <p>
          {callout?.address?.street} {callout?.address?.nr}
          <br />
          {callout?.address?.postCode} {callout?.address?.city}
          <br />
          <a href={`mailto:${callout?.dealer?.email}`}>
            {callout?.dealer?.email}
          </a>
        </p>
      </Column>
      <Column>
        <ResultAction
          height="4rem"
          width="4rem"
          onClick={openPhoneDialog(callout)}
        >
          <Image
            title={phoneLabel}
            src={callIcon}
            alt={phoneLabel}
            style={{ height: '24px', width: '24px' }}
          />
          <span>{phoneLabel}</span>
        </ResultAction>
        <ResultAction height="4rem" width="4rem" onClick={openModal(callout)}>
          <Image
            title={requestQuoteLabel}
            src={requestIcon}
            alt={requestQuoteLabel}
            style={{ height: '24px', width: '24px' }}
          />
          <span>{requestQuoteLabel}</span>
        </ResultAction>
      </Column>
    </CalloutWrapper>
  )
}

export default function StoryblokLocator ({ data }) {
  const [resultCount, loadMore] = useReducer(state => state + 3, 3)
  const [showFilter, toggleFilter] = useReducer(state => !state, true)
  const [dialogPhoneNumber, setDialogPhoneNumber] = useState(false)
  const [modalProps, setModalProps] = useState(false)
  const [callout, setCallout] = useState()
  const [location, setLocation] = useState({})
  const [
    {
      gmap, maps, geocoder, circle, controlDiv, createReverseGeocode
    },
    setMapRefs
  ] = useState({})
  const [stores, setStores] = useState()
  const [results, setResults] = useState([])
  const [radius, setRadius] = useState(Number(data.defaultRadius))
  const [filterObj, setFilter] = useState({})
  const searchInput = useRef()
  const cluster = useRef()
  const { history } = useContext(NavContext) || { history: [] }

  const historyContext = propOr({}, 'context', nth(-2, history))

  const product = useProduct(historyContext?._product)
  const productContext = useContext(ProductContext)

  const { countryCode } = useCountryCode()
  const { search } = useLocation()

  const openPhoneDialog = memoizeWith(prop('id'), dealer => () => {
    gtmPush(dealer, 'dealer-call')
    setDialogPhoneNumber(path(['dealer', 'phone'], dealer))
  })

  const openModal = memoizeWith(prop('id'), dealer => () => {
    setModalProps({
      key: Math.random(),
      dealer,
      prefetch: location.geocode,
      formData: historyContext
    })
  })

  const closeModal = useCallback(() => {
    setModalProps(false)
  })

  const updateFilter = p => e =>
    setFilter(assocPath(p, e.target.checked, filterObj))

  historyContext.message = compose(
    unless(isEmpty, concat(data.messageTemplate)),
    join(','),
    values,
    map(
      p => `${p[0].component.group[0].component.title}: ${p[0].component.title}`
    ),
    pick(values(productContext[(product?.id)])),
    groupBy(prop('id')),
    unnest,
    map(
      converge(map, [
        o(
          assocPath(['component', 'group']),
          pathOr([{}], ['component', 'group'])
        ),
        pathOr([], ['component', 'properties'])
      ])
    )
  )(product?.component?.properties || [])

  const getLocation = useCallback(
    () =>
      createReverseGeocode
      && gmap
      && navigator.geolocation.getCurrentPosition((position) => {
        const loc = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        setLocation({
          gps: true,
          ...loc,
          geocode: createReverseGeocode(loc)
        })
        gmap.setCenter(loc)
      }),
    [gmap, createReverseGeocode]
  )

  // on search field "Enter", use the first result from the dropdown
  useEffect(
    () => {
      if (!searchInput.current) return () => {}

      // fn dispatches down-arrow keypress before enter to select first result
      function selectFirstResult (e) {
        if (e.key === 'Enter') {
          e.preventDefault()
          searchInput.current.dispatchEvent(
            new KeyboardEvent('keydown', { keyCode: 40 })
          )
          searchInput.current.dispatchEvent(
            new KeyboardEvent('keydown', { keyCode: 13 })
          )
        }
      }

      // remove the above fn if down-key is pressed manually
      function removeKeydownSim (e) {
        if (e.keyCode === 40) {
          searchInput.current.removeEventListener('keydown', selectFirstResult)
        }
      }

      searchInput.current.addEventListener('keydown', selectFirstResult)
      searchInput.current.addEventListener('keydown', removeKeydownSim)

      return () => {
        searchInput.current.removeEventListener('keydown', selectFirstResult)
        searchInput.current.removeEventListener('keydown', removeKeydownSim)
      }
    },
    [searchInput.current]
  )

  // Create cluster of map markers (dealers)
  useEffect(
    () => {
      if (gmap && stores && maps) {
        const markers = compose(
          map((store) => {
            const marker = new maps.Marker({
              position: store.location,
              map: gmap
            })
            marker.addListener('click', () => {
              setCallout({ ...store, distance: 0 })
              setLocation({
                lat: store.location.lat,
                lng: store.location.lng,
                gps: false,
                geocode: createReverseGeocode(store.location)
              })
            })
            return marker
          }),
          filter(store =>
            all(identity)(
              unnest(
                values(
                  mapObjIndexed(
                    (groupObj, group) =>
                      values(
                        mapObjIndexed(
                          (bool, key) => !bool || store?.[group]?.[key],
                          groupObj
                        )
                      ),
                    filterObj
                  )
                )
              )
            )
          )
        )(stores)
        if (cluster.current) {
          cluster.current.clearMarkers()
          cluster.current.addMarkers(markers)
        } else {
          cluster.current = new MarkerClusterer(gmap, markers, {
            maxZoom: 11,
            zoomOnClick: false,
            styles: [
              { url: m1, height: 50, width: 50 },
              { url: m2, height: 60, width: 60 },
              { url: m3, height: 70, width: 70 }
            ],
            gridSize: 50
          })
        }
      }
    },
    [gmap, maps, stores, filterObj]
  )

  const [mapApiProps, setMapApiProps] = useState(false) // will hold map props on init
  useEffect(
    () => {
      if (!mapApiProps) return

      const { maps: _maps, map: _map } = mapApiProps

      const createDiv = document.createElement('div')
      const _geocoder = new _maps.Geocoder()
      const _createReverseGeocode = loc => () =>
        new Promise((resolve) => {
          _geocoder.geocode({ location: loc }, (result, status) =>
            resolve({ result, status })
          )
        }).then(({ result, status }) => {
          if (status === 'OK') {
            return { ...parseGeocodeResult(result), placesResult: result?.[0] }
          }
        })
      _map.addListener('click', ({ latLng }) => {
        setLocation({
          lat: latLng.lat(),
          lng: latLng.lng(),
          gps: false,
          geocode: _createReverseGeocode({
            lat: latLng.lat(),
            lng: latLng.lng()
          })
        })
      })
      _map.controls[_maps.ControlPosition.RIGHT_BOTTOM].push(createDiv)
      const _circle = new _maps.Circle({
        clickable: false,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: _map,
        center: pick(['lat', 'lng'], location),
        radius: radius * 1000
      })

      // create / config autocomplete instance
      const _autocomplete = new _maps.places.Autocomplete(searchInput.current, {
        fields: ['geometry.location'],
        bounds: countryBoundaries[countryCode],
        strictBounds: false,
        ...(data.countryKey && {
          componentRestrictions: {
            country: data.countryKey
          }
        })
      })

      // attach listener to update the
      _autocomplete.addListener('place_changed', () => {
        const result = _autocomplete.getPlace()

        if (result.geometry) {
          const loc = result.geometry.location
          setLocation({
            lat: loc.lat(),
            lng: loc.lng(),
            gps: false,
            geocode: _createReverseGeocode({
              lat: loc.lat(),
              lng: loc.lng()
            })
          })
          _map.setCenter(loc)
        } else {
          _geocoder.geocode({ address: result.name }, (res, status) => {
            if (status === 'OK') {
              const loc = res[0].geometry.location
              setLocation({
                lat: loc.lat(),
                lng: loc.lng(),
                gps: false,
                geocode: () =>
                  Promise.resolve({
                    ...parseGeocodeResult(res),
                    placesResult: res?.[0]
                  })
              })
              _map.setCenter(loc)
            }
          })
        }
      })

      // Make map refs globally available
      setMapRefs({
        gmap: _map,
        maps: _maps,
        geocoder: _geocoder,
        circle: _circle,
        autocomplete: _autocomplete,
        createReverseGeocode: _createReverseGeocode,
        controlDiv: createDiv
      })
    },
    [mapApiProps]
  )


  // run the initial search
  useEffect(
    () => {
      if (!maps?.places || !search) return

      const query = search
        .substring(1)
        .split('&')
        .map(x => x.split('='))
        .find(x => x[0] === 'query')
      if (!query || !query[1]) return

      searchInput.current.value = decodeURI(query[1])

      async function runInitialSearch () {
        const {
          predictions
        } = await new maps.places.AutocompleteService().getPlacePredictions({
          input: decodeURI(query[1]),
          fields: ['geometry.location'],
          bounds: countryBoundaries[countryCode],
          strictBounds: false
        })

        if (!predictions?.length) {
          console.warn('Failed to fetch initial search result')
          return
        }

        geocoder.geocode(
          {
            address: predictions[0].description,
            bounds: countryBoundaries[countryCode]
            // region: 'EU'
          },
          (res, status) => {
            if (status === 'OK') {
              const loc = res[0].geometry.location
              setLocation({
                lat: loc.lat(),
                lng: loc.lng(),
                gps: false,
                geocode: () =>
                  Promise.resolve({
                    ...parseGeocodeResult(res),
                    placesResult: res?.[0]
                  })
              })
              gmap.setCenter(loc)
              searchInput.current.focus()
            }
          }
        )
      }
      runInitialSearch()
    },
    [maps, geocoder, gmap, countryCode, search]
  )

  // render circle on map and populate results list
  useEffect(
    () => {
      if (circle) {
        circle.setCenter(pick(['lat', 'lng'], location))
        circle.setRadius(radius * 1000)
      }
      if (location.lat && stores) {
        const toMeters = {
          lat: meterPerDeg,
          lng: meterPerDeg * Math.cos((Math.PI * location.lat) / 180)
        }
        setResults(
          compose(
            // Following compose adds selected dealer (callout) as first item of results
            compose(
              uniqBy(prop('id')),
              filter(Boolean),
              prepend(callout || null)
            ),
            filter(store =>
              all(identity)(
                unnest(
                  values(
                    mapObjIndexed(
                      (groupObj, group) =>
                        values(
                          mapObjIndexed(
                            (bool, key) => !bool || store?.[group]?.[key],
                            groupObj
                          )
                        ),
                      filterObj
                    )
                  )
                )
              )
            ),
            filter(o(gt(radius * radius * 1000000), prop('distance'))),
            map(
              converge(assoc('distance'), [
                compose(
                  sum,
                  map(x => x * x), // converge(multiply, [identity, identity])),
                  values,
                  mergeWith(multiply, toMeters),
                  mergeWith(subtract, pick(['lat', 'lng'], location)),
                  prop('location')
                ),
                identity
              ])
            )
          )(stores)
        )
      }
    },
    [location, stores, radius, filterObj, callout]
  )

  // init the store list (?)
  useEffect(
    () => {
      async function loadStores () {
        const response = await fetch(
          data.database || '/rt-db/locator-stores.json'
        )
        const result = await response.json()
        setStores(
          compose(
            map(
              evolve({
                location: applySpec({ lat: prop('lat'), lng: prop('lon') })
              })
            ),
            unless(
              always(isNil(data.countryFilter) || isEmpty(data.countryFilter)),
              filter(o(contains(__, data.countryFilter), prop('country')))
            ),
            filter(both(prop('location'), prop('dealer'))),
            map(apply(assoc('id'))),
            toPairs
          )(result)
        )
      }
      loadStores()
    },
    [data.database]
  )

  const updateRadius = useCallback(
    e => setRadius(Math.min(e.target.value, data.maxRadius)),
    []
  )

  return (
    <>
      <Dialog onClose={openPhoneDialog(false)} open={!!dialogPhoneNumber}>
        <DialogTitle onClose={openPhoneDialog(false)}>
          {data.phoneLabel}
        </DialogTitle>
        <DialogContent>{dialogPhoneNumber}</DialogContent>
        <DialogActions>
          <a href={`tel:${dialogPhoneNumber}`}>
            <Button>{data.callLabel}</Button>
          </a>
          <Button onClick={openPhoneDialog(false)}>{data.closeLabel}</Button>
        </DialogActions>
      </Dialog>
      <Modal
        onRequestClose={closeModal}
        open={!!modalProps}
        slug={data.action?.story?.url
          .split('/')
          .filter(x => x !== '')
          .map(x => x.replace(/^.._..$/, l => l.replace('_', '-')))
          .join('/')}
        props={modalProps || {}}
      />

      <Background
        bg="white"
        root
        previous="white"
        next="white"
        max="z-index: 501;"
        extraWide
      >
        <NoDealerItem as={Link} link={data.noDealerLink}>
          <h2>{data.noDealerTitle}</h2>
          <p>{data.noDealerText}</p>
        </NoDealerItem>
      </Background>
      <Background
        bg="lightGray"
        root
        previous="lightGray"
        next="lightGray"
        max="z-index: 501;"
        extraWide
      >
        {controlDiv
          && ReactDOM.createPortal(
            <LocationButton onClick={getLocation} title="Your Location">
              {location.gps ? <GpsFixed /> : <GpsNotFixed />}
            </LocationButton>,
            controlDiv
          )}
        <Container spacing={1} container>
          <Item xs={12} item>
            <MapContainer>
              <FilterContainer out={showFilter}>
                <FilterButton out={showFilter} onClick={toggleFilter} />
                <FilterLabel>{data.filterTranslation}</FilterLabel>
                {unnest(
                  values(
                    mapObjIndexed(
                      (obj, group) => (
                        <InputGroup>
                          <GroupLabel>{data[`${group}Translation`]}</GroupLabel>
                          {values(
                            mapObjIndexed(
                              (label, key) =>
                                label && (
                                  <Checkbox
                                    onChange={updateFilter([group, key])}
                                    checked={path([group, key], filterObj)}
                                    id={key}
                                    label={label}
                                    key={key}
                                  />
                                ),
                              obj
                            )
                          )}
                        </InputGroup>
                      ),
                      {
                        products: {
                          garageDoors: data.rgdTranslation,
                          industry: data.idTranslation,
                          doors: data.doorsTranslation
                        },
                        services: {
                          consultation: data.consultationTranslation,
                          assembly: data.assemblyTranslation,
                          service: data.serviceTranslation
                        }
                      }
                    )
                  )
                )}
                <InputGroup>
                  <GroupLabel>{data.locationTranslation}</GroupLabel>

                  <StyledInputLabel htmlFor="query">
                    {data.queryLabel}
                  </StyledInputLabel>
                  <StyledTextField
                    id="query"
                    inputRef={searchInput}
                    fullWidth
                  />
                  <StyledInputLabel htmlFor="radius">
                    {data.radiusLabel}
                  </StyledInputLabel>
                  <StyledTextField
                    id="radius"
                    type="number"
                    value={radius}
                    fullWidth
                    onChange={updateRadius}
                  />
                </InputGroup>
              </FilterContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyBB7vcZ9gtYecNDxH22BJwthLo7e45jXF8',
                  libraries: 'places'
                }}
                defaultCenter={{
                  lat: Number(data.defaultLat),
                  lng: Number(data.defaultLng)
                }}
                defaultZoom={7}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={mapProps => setMapApiProps(mapProps)}
              >
                {callout && (
                  <Callout
                    data={data}
                    callout={callout}
                    setCallout={setCallout}
                    lat={callout?.location?.lat}
                    lng={callout?.location?.lng}
                    openPhoneDialog={openPhoneDialog}
                    openModal={openModal}
                  />
                )}
                {/* map(
                store => (
                  <Marker {...store.location} />
                ),
                values(stores || [])
              ) */}
              </GoogleMapReact>
            </MapContainer>
          </Item>

          {compose(
            map(result => (
              <Item key={result.id} xs={12} item>
                <Content
                  media={device =>
                    (device === 'mobile'
                      ? 'flex-direction:column; align-items: center; height:auto;'
                      : 'flex-direction:row;')
                  }
                >
                  <ResultItem>
                    <GroupLabel>{result.name}</GroupLabel>
                    <p>
                      {result.address?.street} {result.address?.nr}
                    </p>
                    <p>
                      {result.address?.postCode} {result.address?.city}
                    </p>
                    <p>
                      <a href={`mailto:${result.dealer?.email}`}>
                        {result.dealer?.email}
                      </a>
                    </p>
                  </ResultItem>
                  {unnest(
                    values(
                      mapObjIndexed(
                        (obj, group) => (
                          <ResultItem>
                            <GroupLabel>
                              {data[`${group}Translation`]}
                            </GroupLabel>
                            {values(
                              mapObjIndexed(
                                (label, key) =>
                                  label && (
                                    <Checkbox
                                      checked={path([group, key], result)}
                                      id={key}
                                      disabed
                                      key={key}
                                      label={label}
                                    />
                                  ),
                                obj
                              )
                            )}
                          </ResultItem>
                        ),
                        {
                          products: {
                            garageDoors: data.rgdTranslation,
                            industry: data.idTranslation,
                            doors: data.doorsTranslation
                          },
                          services: {
                            consultation: data.consultationTranslation,
                            assembly: data.assemblyTranslation,
                            service: data.serviceTranslation
                          }
                        }
                      )
                    )
                  )}
                  <ResultItem>
                    <GroupLabel>{data.distanceLabel}</GroupLabel>
                    <p>{(Math.sqrt(result.distance) / 1000).toFixed(1)} km</p>
                  </ResultItem>
                  <ResultActions>
                    <ResultAction onClick={openPhoneDialog(result)}>
                      <Image
                        title={data.phoneLabel}
                        src={data.callIcon}
                        alt={data.phoneLabel}
                      />
                      <span>{data.phoneLabel}</span>
                    </ResultAction>
                    <ResultAction onClick={openModal(result)}>
                      <Image
                        title={data.requestQuoteLabel}
                        src={data.requestIcon}
                        alt={data.requestQuoteLabel}
                        style={{ padding: '25px' }}
                      />
                      <span>{data.requestQuoteLabel}</span>
                    </ResultAction>
                  </ResultActions>
                </Content>
              </Item>
            )),
            take(resultCount),
            sortResults
          )(results)}
          {results.length > resultCount && (
            <Item xs={12} item>
              <More>
                <Button onClick={loadMore}>
                  {data.loadMoreLabel || 'Load More'}
                </Button>
              </More>
            </Item>
          )}
        </Container>
      </Background>
      <Background
        bg="white"
        root
        previous="white"
        next="white"
        max="z-index: 501;"
        extraWide
      >
        <NoDealerItem as={Link} link={data.noDealerLink}>
          <h2>{data.noDealerTitle}</h2>
          <p>{data.noDealerText}</p>
        </NoDealerItem>
      </Background>
    </>
  )
}
