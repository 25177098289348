const countryBoundaries = {
  AF: {
    north: 38.4862816432,
    south: 29.318572496,
    east: 75.1580277851,
    west: 60.5284298033
  },
  AO: {
    north: -4.43802336998,
    south: -17.9306364885,
    east: 24.0799052263,
    west: 11.6400960629
  },
  AL: {
    north: 42.6882473822,
    south: 39.624997667,
    east: 21.0200403175,
    west: 19.3044861183
  },
  AE: {
    north: 26.055464179,
    south: 22.4969475367,
    east: 56.3968473651,
    west: 51.5795186705
  },
  AR: {
    north: -21.8323104794,
    south: -55.25,
    east: -53.628348965,
    west: -73.4154357571
  },
  AM: {
    north: 41.2481285671,
    south: 38.7412014837,
    east: 46.5057198423,
    west: 43.5827458026
  },
  AQ: {
    north: -63.2706604895,
    south: -90.0,
    east: 180.0,
    west: -180.0
  },
  TF: {
    north: -48.625,
    south: -49.775,
    east: 70.56,
    west: 68.72
  },
  AU: {
    north: -10.6681857235,
    south: -43.6345972634,
    east: 153.569469029,
    west: 113.338953078
  },
  AT: {
    north: 49.0390742051,
    south: 46.4318173285,
    east: 16.9796667823,
    west: 9.47996951665
  },
  AZ: {
    north: 41.8606751572,
    south: 38.2703775091,
    east: 50.3928210793,
    west: 44.7939896991
  },
  BI: {
    north: -2.34848683025,
    south: -4.49998341229,
    east: 30.752262811,
    west: 29.0249263852
  },
  BE: {
    north: 51.4750237087,
    south: 49.5294835476,
    east: 6.15665815596,
    west: 2.51357303225
  },
  BJ: {
    north: 12.2356358912,
    south: 6.14215770103,
    east: 3.79711225751,
    west: 0.772335646171
  },
  BF: {
    north: 15.1161577418,
    south: 9.61083486576,
    east: 2.17710778159,
    west: -5.47056494793
  },
  BD: {
    north: 26.4465255803,
    south: 20.670883287,
    east: 92.6727209818,
    west: 88.0844222351
  },
  BG: {
    north: 44.2349230007,
    south: 41.2344859889,
    east: 28.5580814959,
    west: 22.3805257504
  },
  BS: {
    north: 27.04,
    south: 23.71,
    east: -77.0,
    west: -78.98
  },
  BA: {
    north: 45.2337767604,
    south: 42.65,
    east: 19.59976,
    west: 15.7500260759
  },
  BY: {
    north: 56.1691299506,
    south: 51.3195034857,
    east: 32.6936430193,
    west: 23.1994938494
  },
  BZ: {
    north: 18.4999822047,
    south: 15.8869375676,
    east: -88.1068129138,
    west: -89.2291216703
  },
  BO: {
    north: -9.76198780685,
    south: -22.8729187965,
    east: -57.4983711412,
    west: -69.5904237535
  },
  BR: {
    north: 5.24448639569,
    south: -33.7683777809,
    east: -34.7299934555,
    west: -73.9872354804
  },
  BN: {
    north: 5.44772980389,
    south: 4.007636827,
    east: 115.450710484,
    west: 114.204016555
  },
  BT: {
    north: 28.2964385035,
    south: 26.7194029811,
    east: 92.1037117859,
    west: 88.8142484883
  },
  BW: {
    north: -17.6618156877,
    south: -26.8285429827,
    east: 29.4321883481,
    west: 19.8954577979
  },
  CF: {
    north: 11.1423951278,
    south: 2.2676396753,
    east: 27.3742261085,
    west: 14.4594071794
  },
  CA: {
    north: 83.23324,
    south: 41.6751050889,
    east: -52.6480987209,
    west: -140.99778
  },
  CH: {
    north: 47.8308275417,
    south: 45.7769477403,
    east: 10.4427014502,
    west: 6.02260949059
  },
  CL: {
    north: -17.5800118954,
    south: -55.61183,
    east: -66.95992,
    west: -75.6443953112
  },
  CN: {
    north: 53.4588044297,
    south: 18.197700914,
    east: 135.026311477,
    west: 73.6753792663
  },
  CI: {
    north: 10.5240607772,
    south: 4.33828847902,
    east: -2.56218950033,
    west: -8.60288021487
  },
  CM: {
    north: 12.8593962671,
    south: 1.72767263428,
    east: 16.0128524106,
    west: 8.48881554529
  },
  CD: {
    north: 5.25608775474,
    south: -13.2572266578,
    east: 31.1741492042,
    west: 12.1823368669
  },
  CG: {
    north: 3.72819651938,
    south: -5.03798674888,
    east: 18.4530652198,
    west: 11.0937728207
  },
  CO: {
    north: 12.4373031682,
    south: -4.29818694419,
    east: -66.8763258531,
    west: -78.9909352282
  },
  CR: {
    north: 11.2171192489,
    south: 8.22502798099,
    east: -82.5461962552,
    west: -85.94172543
  },
  CU: {
    north: 23.1886107447,
    south: 19.8554808619,
    east: -74.1780248685,
    west: -84.9749110583
  },
  CY: {
    north: 35.1731247015,
    south: 34.5718694118,
    east: 34.0048808123,
    west: 32.2566671079
  },
  CZ: {
    north: 51.1172677679,
    south: 48.5553052842,
    east: 18.8531441586,
    west: 12.2401111182
  },
  DE: {
    north: 54.983104153,
    south: 47.3024876979,
    east: 15.0169958839,
    west: 5.98865807458
  },
  DJ: {
    north: 12.6996385767,
    south: 10.9268785669,
    east: 43.3178524107,
    west: 41.66176
  },
  DK: {
    north: 57.730016588,
    south: 54.8000145534,
    east: 12.6900061378,
    west: 8.08997684086
  },
  DO: {
    north: 19.8849105901,
    south: 17.598564358,
    east: -68.3179432848,
    west: -71.9451120673
  },
  DZ: {
    north: 37.1183806422,
    south: 19.0573642034,
    east: 11.9995056495,
    west: -8.68439978681
  },
  EC: {
    north: 1.3809237736,
    south: -4.95912851321,
    east: -75.2337227037,
    west: -80.9677654691
  },
  EG: {
    north: 31.58568,
    south: 22.0,
    east: 36.86623,
    west: 24.70007
  },
  ER: {
    north: 17.9983074,
    south: 12.4554157577,
    east: 43.0812260272,
    west: 36.3231889178
  },
  ES: {
    north: 43.7483377142,
    south: 35.946850084,
    east: 3.03948408368,
    west: -9.39288367353
  },
  EE: {
    north: 59.6110903998,
    south: 57.4745283067,
    east: 28.1316992531,
    west: 23.3397953631
  },
  ET: {
    north: 14.95943,
    south: 3.42206,
    east: 47.78942,
    west: 32.95418
  },
  FI: {
    north: 70.1641930203,
    south: 59.846373196,
    east: 31.5160921567,
    west: 20.6455928891
  },
  FJ: {
    north: -16.0208822567,
    south: -18.28799,
    east: 180.0,
    west: -180.0
  },
  FK: {
    north: -51.1,
    south: -52.3,
    east: -57.75,
    west: -61.2
  },
  FR: {
    north: 51.1485061713,
    south: 2.05338918702,
    east: 9.56001631027,
    west: -54.5247541978
  },
  GA: {
    north: 2.32675751384,
    south: -3.97882659263,
    east: 14.4254557634,
    west: 8.79799563969
  },
  GB: {
    north: 58.6350001085,
    south: 49.959999905,
    east: 1.68153079591,
    west: -7.57216793459
  },
  GE: {
    north: 43.553104153,
    south: 41.0644446885,
    east: 46.6379081561,
    west: 39.9550085793
  },
  GH: {
    north: 11.0983409693,
    south: 4.71046214438,
    east: 1.0601216976,
    west: -3.24437008301
  },
  GN: {
    north: 12.5861829696,
    south: 7.3090373804,
    east: -7.83210038902,
    west: -15.1303112452
  },
  GM: {
    north: 13.8764918075,
    south: 13.1302841252,
    east: -13.8449633448,
    west: -16.8415246241
  },
  GW: {
    north: 12.6281700708,
    south: 11.0404116887,
    east: -13.7004760401,
    west: -16.6774519516
  },
  GQ: {
    north: 2.28386607504,
    south: 1.01011953369,
    east: 11.285078973,
    west: 9.3056132341
  },
  GR: {
    north: 41.8269046087,
    south: 34.9199876979,
    east: 26.6041955909,
    west: 20.1500159034
  },
  GL: {
    north: 83.64513,
    south: 60.03676,
    east: -12.20855,
    west: -73.297
  },
  GT: {
    north: 17.8193260767,
    south: 13.7353376327,
    east: -88.2250227526,
    west: -92.2292486234
  },
  GY: {
    north: 8.36703481692,
    south: 1.26808828369,
    east: -56.5393857489,
    west: -61.4103029039
  },
  HN: {
    north: 16.0054057886,
    south: 12.9846857772,
    east: -83.147219001,
    west: -89.3533259753
  },
  HR: {
    north: 46.5037509222,
    south: 42.47999136,
    east: 19.3904757016,
    west: 13.6569755388
  },
  HT: {
    north: 19.9156839055,
    south: 18.0309927434,
    east: -71.6248732164,
    west: -74.4580336168
  },
  HU: {
    north: 48.6238540716,
    south: 45.7594811061,
    east: 22.710531447,
    west: 16.2022982113
  },
  ID: {
    north: 5.47982086834,
    south: -10.3599874813,
    east: 141.03385176,
    west: 95.2930261576
  },
  IN: {
    north: 35.4940095078,
    south: 7.96553477623,
    east: 97.4025614766,
    west: 68.1766451354
  },
  IE: {
    north: 55.1316222195,
    south: 51.6693012559,
    east: -6.03298539878,
    west: -9.97708574059
  },
  IR: {
    north: 39.7130026312,
    south: 25.0782370061,
    east: 63.3166317076,
    west: 44.1092252948
  },
  IQ: {
    north: 37.3852635768,
    south: 29.0990251735,
    east: 48.5679712258,
    west: 38.7923405291
  },
  IS: {
    north: 66.5267923041,
    south: 63.4963829617,
    east: -13.609732225,
    west: -24.3261840479
  },
  IL: {
    north: 33.2774264593,
    south: 29.5013261988,
    east: 35.8363969256,
    west: 34.2654333839
  },
  IT: {
    north: 47.1153931748,
    south: 36.619987291,
    east: 18.4802470232,
    west: 6.7499552751
  },
  JM: {
    north: 18.5242184514,
    south: 17.7011162379,
    east: -76.1996585761,
    west: -78.3377192858
  },
  JO: {
    north: 33.3786864284,
    south: 29.1974946152,
    east: 39.1954683774,
    west: 34.9226025734
  },
  JP: {
    north: 45.5514834662,
    south: 31.0295791692,
    east: 145.543137242,
    west: 129.408463169
  },
  KZ: {
    north: 55.3852501491,
    south: 40.6623245306,
    east: 87.3599703308,
    west: 46.4664457538
  },
  KE: {
    north: 5.506,
    south: -4.67677,
    east: 41.8550830926,
    west: 33.8935689697
  },
  KG: {
    north: 43.2983393418,
    south: 39.2794632025,
    east: 80.2599902689,
    west: 69.464886916
  },
  KH: {
    north: 14.5705838078,
    south: 10.4865436874,
    east: 107.614547968,
    west: 102.3480994
  },
  KR: {
    north: 38.6122429469,
    south: 34.3900458847,
    east: 129.468304478,
    west: 126.117397903
  },
  KW: {
    north: 30.0590699326,
    south: 28.5260627304,
    east: 48.4160941913,
    west: 46.5687134133
  },
  LA: {
    north: 22.4647531194,
    south: 13.88109101,
    east: 107.564525181,
    west: 100.115987583
  },
  LB: {
    north: 34.6449140488,
    south: 33.0890400254,
    east: 36.6117501157,
    west: 35.1260526873
  },
  LR: {
    north: 8.54105520267,
    south: 4.35575511313,
    east: -7.53971513511,
    west: -11.4387794662
  },
  LY: {
    north: 33.1369957545,
    south: 19.58047,
    east: 25.16482,
    west: 9.31941084152
  },
  LK: {
    north: 9.82407766361,
    south: 5.96836985923,
    east: 81.7879590189,
    west: 79.6951668639
  },
  LS: {
    north: -28.6475017229,
    south: -30.6451058896,
    east: 29.3251664568,
    west: 26.9992619158
  },
  LT: {
    north: 56.3725283881,
    south: 53.9057022162,
    east: 26.5882792498,
    west: 21.0558004086
  },
  LU: {
    north: 50.1280516628,
    south: 49.4426671413,
    east: 6.24275109216,
    west: 5.67405195478
  },
  LV: {
    north: 57.9701569688,
    south: 55.61510692,
    east: 28.1767094256,
    west: 21.0558004086
  },
  MA: {
    north: 35.7599881048,
    south: 21.4207341578,
    east: -1.12455115397,
    west: -17.0204284327
  },
  MD: {
    north: 48.4671194525,
    south: 45.4882831895,
    east: 30.0246586443,
    west: 26.6193367856
  },
  MG: {
    north: -12.0405567359,
    south: -25.6014344215,
    east: 50.4765368996,
    west: 43.2541870461
  },
  MX: {
    north: 32.72083,
    south: 14.5388286402,
    east: -86.811982388,
    west: -117.12776
  },
  MK: {
    north: 42.3202595078,
    south: 40.8427269557,
    east: 22.9523771502,
    west: 20.46315
  },
  ML: {
    north: 24.9745740829,
    south: 10.0963607854,
    east: 4.27020999514,
    west: -12.1707502914
  },
  MM: {
    north: 28.335945136,
    south: 9.93295990645,
    east: 101.180005324,
    west: 92.3032344909
  },
  ME: {
    north: 43.52384,
    south: 41.87755,
    east: 20.3398,
    west: 18.45
  },
  MN: {
    north: 52.0473660345,
    south: 41.5974095729,
    east: 119.772823928,
    west: 87.7512642761
  },
  MZ: {
    north: -10.3170960425,
    south: -26.7421916643,
    east: 40.7754752948,
    west: 30.1794812355
  },
  MR: {
    north: 27.3957441269,
    south: 14.6168342147,
    east: -4.92333736817,
    west: -17.0634232243
  },
  MW: {
    north: -9.23059905359,
    south: -16.8012997372,
    east: 35.7719047381,
    west: 32.6881653175
  },
  MY: {
    north: 6.92805288332,
    south: 0.773131415201,
    east: 119.181903925,
    west: 100.085756871
  },
  NA: {
    north: -16.9413428687,
    south: -29.045461928,
    east: 25.0844433937,
    west: 11.7341988461
  },
  NC: {
    north: -20.1056458473,
    south: -22.3999760881,
    east: 167.120011428,
    west: 164.029605748
  },
  NE: {
    north: 23.4716684026,
    south: 11.6601671412,
    east: 15.9032466977,
    west: 0.295646396495
  },
  NG: {
    north: 13.8659239771,
    south: 4.24059418377,
    east: 14.5771777686,
    west: 2.69170169436
  },
  NI: {
    north: 15.0162671981,
    south: 10.7268390975,
    east: -83.147219001,
    west: -87.6684934151
  },
  NL: {
    north: 53.5104033474,
    south: 50.803721015,
    east: 7.09205325687,
    west: 3.31497114423
  },
  NO: {
    north: 80.6571442736,
    south: 58.0788841824,
    east: 31.29341841,
    west: 4.99207807783
  },
  NP: {
    north: 30.4227169866,
    south: 26.3978980576,
    east: 88.1748043151,
    west: 80.0884245137
  },
  NZ: {
    north: -34.4506617165,
    south: -46.641235447,
    east: 178.517093541,
    west: 166.509144322
  },
  OM: {
    north: 26.3959343531,
    south: 16.6510511337,
    east: 59.8080603372,
    west: 52.0000098
  },
  PK: {
    north: 37.1330309108,
    south: 23.6919650335,
    east: 77.8374507995,
    west: 60.8742484882
  },
  PA: {
    north: 9.61161001224,
    south: 7.2205414901,
    east: -77.2425664944,
    west: -82.9657830472
  },
  PE: {
    north: -0.0572054988649,
    south: -18.3479753557,
    east: -68.6650797187,
    west: -81.4109425524
  },
  PH: {
    north: 18.5052273625,
    south: 5.58100332277,
    east: 126.537423944,
    west: 117.17427453
  },
  PG: {
    north: -2.50000212973,
    south: -10.6524760881,
    east: 156.019965448,
    west: 141.000210403
  },
  PL: {
    north: 54.8515359564,
    south: 49.0273953314,
    east: 24.0299857927,
    west: 14.0745211117
  },
  PR: {
    north: 18.5206011011,
    south: 17.946553453,
    east: -65.5910037909,
    west: -67.2424275377
  },
  KP: {
    north: 42.9853868678,
    south: 37.669070543,
    east: 130.780007359,
    west: 124.265624628
  },
  PT: {
    north: 42.280468655,
    south: 36.838268541,
    east: -6.3890876937,
    west: -9.52657060387
  },
  PY: {
    north: -19.3427466773,
    south: -27.5484990374,
    east: -54.2929595608,
    west: -62.6850571357
  },
  QA: {
    north: 26.1145820175,
    south: 24.5563308782,
    east: 51.6067004738,
    west: 50.7439107603
  },
  RO: {
    north: 48.2208812526,
    south: 43.6884447292,
    east: 29.62654341,
    west: 20.2201924985
  },
  RU: {
    north: 81.2504,
    south: 41.151416124,
    east: 180.0,
    west: -180.0
  },
  RW: {
    north: -1.13465911215,
    south: -2.91785776125,
    east: 30.8161348813,
    west: 29.0249263852
  },
  SA: {
    north: 32.161008816,
    south: 16.3478913436,
    east: 55.6666593769,
    west: 34.6323360532
  },
  SD: {
    north: 22.0,
    south: 8.61972971293,
    east: 38.4100899595,
    west: 21.93681
  },
  SS: {
    north: 12.2480077571,
    south: 3.50917,
    east: 35.2980071182,
    west: 23.8869795809
  },
  SN: {
    north: 16.5982636581,
    south: 12.332089952,
    east: -11.4678991358,
    west: -17.6250426905
  },
  SB: {
    north: -6.59933847415,
    south: -10.8263672828,
    east: 162.398645868,
    west: 156.491357864
  },
  SL: {
    north: 10.0469839543,
    south: 6.78591685631,
    east: -10.2300935531,
    west: -13.2465502588
  },
  SV: {
    north: 14.4241327987,
    south: 13.1490168319,
    east: -87.7235029772,
    west: -90.0955545723
  },
  SO: {
    north: 12.02464,
    south: -1.68325,
    east: 51.13387,
    west: 40.98105
  },
  RS: {
    north: 46.1717298447,
    south: 42.2452243971,
    east: 22.9860185076,
    west: 18.82982
  },
  SR: {
    north: 6.0252914494,
    south: 1.81766714112,
    east: -53.9580446031,
    west: -58.0446943834
  },
  SK: {
    north: 49.5715740017,
    south: 47.7584288601,
    east: 22.5581376482,
    west: 16.8799829444
  },
  SI: {
    north: 46.8523859727,
    south: 45.4523163926,
    east: 16.5648083839,
    west: 13.6981099789
  },
  SE: {
    north: 69.1062472602,
    south: 55.3617373725,
    east: 23.9033785336,
    west: 11.0273686052
  },
  SZ: {
    north: -25.660190525,
    south: -27.2858794085,
    east: 32.0716654803,
    west: 30.6766085141
  },
  SY: {
    north: 37.2298725449,
    south: 32.312937527,
    east: 42.3495910988,
    west: 35.7007979673
  },
  TD: {
    north: 23.40972,
    south: 7.42192454674,
    east: 23.88689,
    west: 13.5403935076
  },
  TG: {
    north: 11.0186817489,
    south: 5.92883738853,
    east: 1.86524051271,
    west: -0.0497847151599
  },
  TH: {
    north: 20.4178496363,
    south: 5.69138418215,
    east: 105.589038527,
    west: 97.3758964376
  },
  TJ: {
    north: 40.9602133245,
    south: 36.7381712916,
    east: 74.9800024759,
    west: 67.4422196796
  },
  TM: {
    north: 42.7515510117,
    south: 35.2706639674,
    east: 66.5461503437,
    west: 52.5024597512
  },
  TL: {
    north: -8.27334482181,
    south: -9.39317310958,
    east: 127.335928176,
    west: 124.968682489
  },
  TT: {
    north: 10.89,
    south: 10.0,
    east: -60.895,
    west: -61.95
  },
  TN: {
    north: 37.3499944118,
    south: 30.3075560572,
    east: 11.4887874691,
    west: 7.52448164229
  },
  TR: {
    north: 42.1414848903,
    south: 35.8215347357,
    east: 44.7939896991,
    west: 26.0433512713
  },
  TW: {
    north: 25.2954588893,
    south: 21.9705713974,
    east: 121.951243931,
    west: 120.106188593
  },
  TZ: {
    north: -0.95,
    south: -11.7209380022,
    east: 40.31659,
    west: 29.3399975929
  },
  UG: {
    north: 4.24988494736,
    south: -1.44332244223,
    east: 35.03599,
    west: 29.5794661801
  },
  UA: {
    north: 52.3350745713,
    south: 44.3614785833,
    east: 40.0807890155,
    west: 22.0856083513
  },
  UY: {
    north: -30.1096863746,
    south: -34.9526465797,
    east: -53.209588996,
    west: -58.4270741441
  },
  US: {
    north: 71.3577635769,
    south: 18.91619,
    east: -66.96466,
    west: -171.791110603
  },
  UZ: {
    north: 45.5868043076,
    south: 37.1449940049,
    east: 73.055417108,
    west: 55.9289172707
  },
  VE: {
    north: 12.1623070337,
    south: 0.724452215982,
    east: -59.7582848782,
    west: -73.3049515449
  },
  VN: {
    north: 23.3520633001,
    south: 8.59975962975,
    east: 109.33526981,
    west: 102.170435826
  },
  VU: {
    north: -14.6264970842,
    south: -16.5978496233,
    east: 167.844876744,
    west: 166.629136998
  },
  PS: {
    north: 32.5325106878,
    south: 31.3534353704,
    east: 35.5456653175,
    west: 34.9274084816
  },
  YE: {
    north: 19.0000033635,
    south: 12.5859504257,
    east: 53.1085726255,
    west: 42.6048726743
  },
  ZA: {
    north: -22.0913127581,
    south: -34.8191663551,
    east: 32.830120477,
    west: 16.3449768409
  },
  ZM: {
    north: -8.23825652429,
    south: -17.9612289364,
    east: 33.4856876971,
    west: 21.887842645
  },
  ZW: {
    north: -15.5077869605,
    south: -22.2716118303,
    east: 32.8498608742,
    west: 25.2642257016
  }
}

export default countryBoundaries
